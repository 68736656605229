<template>
	<div style="display: flex;	background-color: #fff;">
	<div class="bag">
		<img style="width:2.5rem;height: .40rem;" src="../../assets/img/index/logo.png" alt="" />
		<div style="position: absolute;bottom: .35rem;font-size: .075rem;color: #fff;">
			 <div style="margin-bottom: .06rem;">黄山生命科学研究院 · 长三角真实世界研究中心</div>
			 <div style="margin-bottom: .1rem;">中安九方健康产业 (安徽) 有限公司 技术支持</div>
			 <div style="margin-bottom: .06rem;font-weight:100;">Copyright © 中安九方健康产业 (安徽) 有限公司 &nbsp;  All rights reserved.v2024</div>
		
			<!-- <div style="font-weight:100;">皖ICP备17023624号-6</div> -->
			 <a style="color: #fff;cursor: pointer;" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"  title="皖ICP备17023624号-6">皖ICP备17023624号-6</a>
		</div>

		
		        
		        
		       
	</div>
	<div style="width: 57.8%;height: 100vh;background-color: #fff;">
		<div class="form-con" id="form-con">
		  <div class="form-title">登录</div>
		  <el-form
		    :model="dataForm"
		    :rules="dataRule"
		    ref="dataForm"
		
		  >
		    <el-form-item prop="account">
		      <el-input v-model="dataForm.account" placeholder="请输入用户名">
		        <i slot="suffix" style="display: flex; align-items: center">
		          <img src="../../assets/img/icon_user.png" alt />
		        </i>
		      </el-input>
		    </el-form-item>
		    <el-form-item prop="password">
		      <el-input
		        type="password"
		        v-model="dataForm.password"
		        placeholder="请输入密码"
		      >
		       <i slot="suffix" style="display: flex; align-items: center">
		         <img src="../../assets/img/icon_lock.png" alt />
		       </i>
		      </el-input>
		    </el-form-item>
		   <!-- <el-form-item class="remember-pwd" style="line-height: 0;color: #333;">
		      <el-checkbox v-model="checked">记住我</el-checkbox>
		    </el-form-item> -->
			<div class="remember-pwd">
				   <el-checkbox v-model="checked">记住我</el-checkbox>
			</div>
		  </el-form>
		  <div style="margin-top: 0.07rem;">
		    <el-button class="submit-btn2" type="primary" @click="getCaptcha"
		      >立即登录</el-button
		    >
		  </div>
		</div>
		<!-- <div class="cardArea">
			<div style="font-size: .14rem;font-weight: bold;margin-bottom: .1rem;width: 100%;margin-left: .05rem;">选择专病库平台</div>
			<div @click="changeActive(1)" v-if="activeIndex==1" class="cardItemActive">
				<img src="../../assets/img/index/icons11.png" alt="" />
				<div>EDC-RWS</div>
			</div>
			<div @click="changeActive(1)" v-if="activeIndex!=1" class="cardItem">
				<img src="../../assets/img/index/icon1.png" alt="" />
				<div>EDC-RWS</div>
			</div>
			<div @click="changeActive(2)"  v-if="activeIndex==2"  class="cardItemActive">
				<img src="../../assets/img/index/icons33.png" alt="" />
				<div>脑血管病</div>
			</div>
			<div @click="changeActive(2)"  v-if="activeIndex!=2"  class="cardItem">
				<img src="../../assets/img/index/icon2.png" alt="" />
				<div>脑血管病</div>
			</div>
			<div @click="changeActive(3)"  v-if="activeIndex==3"  class="cardItemActive">
				<img src="../../assets/img/index/icons44.png" alt="" />
				<div>妇科慢病</div>
			</div>
			<div @click="changeActive(3)"  v-if="activeIndex!=3"  class="cardItem">
				<img src="../../assets/img/index/icon3.png" alt="" />
				<div>妇科慢病</div>
			</div>
			<div @click="changeActive(4)"  v-if="activeIndex==4"  class="cardItemActive">
				<img src="../../assets/img/index/icons55.png" alt="" />
				<div>炎性肠炎病</div>
			</div>
			<div @click="changeActive(4)"  v-if="activeIndex!=4"  class="cardItem">
				<img src="../../assets/img/index/icon4.png" alt="" />
				<div>炎性肠炎病</div>
			</div>
			<div @click="changeActive(5)"  v-if="activeIndex==5"  class="cardItemActive">
				<img src="../../assets/img/index/icons66.png" alt="" />
				<div>慢阻肺</div>
			</div>
			<div @click="changeActive(5)"  v-if="activeIndex!=5"  class="cardItem">
				<img src="../../assets/img/index/icon5.png" alt="" />
				<div>慢阻肺</div>
			</div>
			<div @click="changeActive(6)"  v-if="activeIndex==6"  class="cardItemActive">
				<img src="../../assets/img/index/icons22.png" alt="" />
				<div>其它慢病</div>
			</div>
			<div @click="changeActive(6)"  v-if="activeIndex!=6"  class="cardItem">
				<img src="../../assets/img/index/icon6.png" alt="" />
				<div>其它慢病</div>
			</div>
			
		
		</div> -->
	</div>

	</div>
</template>

<script>
	import Qs from "qs";
	var regPhone = /^1[34578]\d{9}$/,
		regPwd = /^[a-z|A-Z|0-9|\-|_]{4,20}$/;
	import {
		getUUID
	} from "../../utils/index";
	import MD5 from "js-md5";
	// 腾讯滑块验证(新建一个js文件)
	import TencentCaptcha from "@/assets/TencentCaptcha";
	export default {
		data() {
			/*var validatePhone = (rule, value, callback) => {
			    if (!value) {
			      callback(new Error('请输入手机号'));
			    } else if (!regPhone.test(value)) {
			      callback(new Error('手机号格式不正确'));
			    } else {
			      callback();
			    }
			  }*/
			var validatePwd = (rule, value, callback) => {
				if (!value) {
					callback(new Error("请输入密码"));
				}
				/*else if (!regPwd.test(value)){
				         callback(new Error('4-20位，字母、数字或_'));
				       }*/
				else {
					callback();
				}
			};
			return {
				checked:false,
				activeIndex:'',
				usernameText: "", //用户名文本
				passwordText: "", //记住密码文本
				dataForm: {
					account: "",
					password: "",
					//codeImg: ''
				},
				year:'',
				dataRule: {
					account: [{
						required: true,
						message: "请输入用户名/手机号",
						trigger: "blur"
					}, ],
					password: [{
						required: true,
						validator: validatePwd,
						trigger: "blur"
					}],
					code: [{
						required: true,
						message: "请输入图形验证码",
						trigger: "blur"
					}, ],
				},
				userInfo: {},
				loading: false,
				uuid: "",
				appId: "2036675646",
				// doctorUnionId: "00ec8279761b4ea78697c182db4e7877",
				unionName: "",
			};
		},
		computed: {},
		mounted() {
			if(localStorage.account){
				this.dataForm = JSON.parse(localStorage.account)
				this.checked = true
			}
			this.year = new Date().getFullYear()
			localStorage.currentTitle = "黄山生命科学研究院 · 长三角真实世界研究中心";
			document.title = "黄山生命科学研究院 · 长三角真实世界研究中心";
		},
		methods: {
			changeActive(val){
				this.activeIndex = val
				if(this.activeIndex==2){
					this.$router.push('/login')
				}else if(this.activeIndex==3){
					window.location.href='https://ky.docteams.cn/#/login?teamsId=6199932308314cfe8cab9377856d137b'
				}else{
						this.$message.warning('专病库建设中...')
				}
			},
			// 获取滑块验证码
			getCaptcha() {
				let _this = this;
if(this.dataForm.account==''||this.dataForm.password==''){
	this.$message.error('请输入用户名和密码')
	return
}

			if(this.checked){
				localStorage.account = JSON.stringify(this.dataForm)
				
			}else{
				localStorage.removeItem('account')
			}
						this.$Captcha.show();
						this.$bus.$on("getTicket", (res) => {
							// 返回结果
							// ret         Int       验证结果，0：验证成功。2：用户主动关闭验证码。
							// ticket      String    验证成功的票据，当且仅当 ret = 0 时 ticket 有值。
							// CaptchaAppId       String    验证码应用ID。
							// bizState    Any       自定义透传参数。
							if (res.ret === 0) {
								let params = {
									randstr: res.randstr,
									ticket: res.ticket,
								};
								_this
									.$http({
										url: _this.$http.adornUrl("/bind/describeCaptchaResult"),
										method: "post",
										data: Qs.stringify(params),
									})
									.then(({
										data
									}) => {
										let captchaCode = JSON.parse(data.data).CaptchaCode;
										if (data.data && captchaCode === 1) {
											_this.dataFormSubmit();
										} else {
											_this.$message.error("滑块校验未通过，请重新尝试");
										}
									});
							} else {
								// 提示用户完成验证
							}
							this.$bus.$off("getTicket");
						});
				
			},
			// 提交表单
			dataFormSubmit() {
			  // 判断复选框是否被勾选; 勾选则调用配置Cookie方法
			  this.$refs["dataForm"].validate((valid) => {
			    if (valid) {
			      let param = {
			        stringParam1: this.dataForm.account,
			        stringParam2: MD5(this.dataForm.password),
			        intParam1: 1,
			        stringParam3: this.doctorUnionId,
			      };
			      localStorage.removeItem("token");
			      this.$httpAes({
			        url: this.$httpAes.adornUrl("/organBusiness/login"),
			        method: "post",
			        data: param,
			      }).then(({ data }) => {
			        if (data.status) {
			          localStorage.userInfo = JSON.stringify(data.data.user);
			          localStorage.dept = JSON.stringify(data.data.dept);
			          localStorage.setItem("token", data.data.token);
			          this.$cookie.set("hospitalId", data.data.user.deptPid);
			          if (
			            data.data.teamsManages &&
			            data.data.teamsManages.length == 0
			          ) {
			            this.$message.error(
			              "当前工作室团队状态异常，请联系系统管理员。"
			            );
			            return;
			          }
			          this.getGzsInfo();
			          this.setUserInfo();
			        } else {
			          this.$message.error(data.msg);
			        }
			      });
			    }
			  });
			},
			setUserInfo() {
			  // 判断用户是否勾选记住密码，如果勾选，向cookie中储存登录信息，
			  // 如果没有勾选，储存的信息为空
			  if (this.checked) {
			    this.setCookie("account", this.dataForm.account);
			    // base64加密密码
			    let passWord = Base64.encode(this.dataForm.password);
			    this.setCookie("password", passWord);
			  } else {
			    this.setCookie("account", "");
			    this.setCookie("password", "");
			  }
			},
			// 获取团队下的病种
			// 获取团队下的病种
			getGzsInfo() {
			  let that = this;
			  that
			    .$httpAes({
			      url: that.$httpAes.adornUrl("/organBusiness/info"),
			      method: "post",
				  data: {},
			    })
			    .then(({ data }) => {
			      if (data.data) {
			        this.$store.commit("common/updateImageUrl", data.data.avatar);
			        sessionStorage.organBusinessInfo = JSON.stringify(data.data);
			        if (data.data.teamsManages && data.data.teamsManages.length) {
			          that.teamsManages = data.data.teamsManages;
			          localStorage.teamsManages = JSON.stringify(that.teamsManages);
			          if (data.data.lastTeamId) {
			            that.currentTeam = that.teamsManages.find((item) => {
			              return item.id == data.data.lastTeamId;
			            });
			            localStorage.currentTeam = JSON.stringify(that.currentTeam);
			            //that.currentTeam = JSON.parse(localStorage.currentTeam);
			          } else {
			            that.currentTeam = data.data.teamsManages[0];
			            localStorage.currentTeam = JSON.stringify(
			              data.data.teamsManages[0]
			            );
			          }
			          this.showTeam = true;
			          this.gzsFlag = true;
			
			          localStorage.gzsFlag = true;
			          this.$router.push({ name: "home" });
			          sessionStorage.loginType = "login";
			        } else if (data.data.roleId == "e89325119fa411e89e43525400557296") {
			          console.log("没工作室+管理员身份");
			          sessionStorage.radio = 1;
			          sessionStorage.loginType = "login";
			          this.$router.push({ name: "home" });
			        } else if (data.data.roleId != "e89325119fa411e89e43525400557296") {
			          localStorage.gzsFlag = false;
			          this.$message.error("您未加入任何工作室，无法访问");
			        }
			      }
			    });
			},
			getUserInfo() {
				let that = this;
				that
					.$http({
						url: that.$http.adornUrl("/organBusiness/info"),
						method: "get",
					})
					.then(({
						data
					}) => {
						if (data.data) {
							if (data.data.avatar) {
								that.$store.commit("common/updateImageUrl", data.data.avatar);
							}
							if (data.data.teamsManages && data.data.teamsManages.length) {
								that.$store.commit(
									"common/updateTeamsManages",
									data.data.teamsManages
								);
								that.$store.commit(
									"common/updateCurrentTeam",
									data.data.teamsManages[0]
								);
								localStorage.teamsManages = JSON.stringify(
									data.data.teamsManages
								);
								localStorage.currentTeam = data.data.teamsManages[0];
							}
						}
					});
			},
		},
	};
</script>

<style lang="scss">
	.form-con {
	  width: 2.39rem; 
	  height: 1.44rem;
	  margin-top: 1.31rem;
	 background-color: #fff;
	 box-sizing: border-box;
	 box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
	 border: 1px solid rgb(237, 237, 237);
	 margin-left: .91rem;
	 padding: .1rem .25rem .23rem .25rem;
	  .form-title {
		  color: #333333;
		  font-weight: bold;
	    font-size: 0.13rem;

	    padding-bottom: 0.07rem;
	  }
	  .el-form-item {
	    position: relative;
	    margin-bottom: 0.09rem;
	    .el-form-item__content {
	      line-height: 0.19rem;
	    }
	    .el-input__inner {
	      height: 0.19rem;
	      font-size: 0.07291667rem;
	    }
	    .el-input--suffix .el-input__inner {
	      // height: .229167rem;
	      padding-right: 0.15625rem;
	    }
	    .el-input__suffix {
	      display: flex;
	      width: 0.104167rem;
	      height: 0.229167rem;
	      justify-content: center;
	    }
	    img {
	      width: 0.1041667rem;
	      height: 0.1041667rem;
		  margin-right: .05rem;
		  margin-top: .04rem;
	      vertical-align: middle;
	    }
	  }
	  .remember-pwd {
	    .el-checkbox {
	      font-size: 0.06rem;
	      .el-checkbox__inner {
	        width: 0.09rem;
	        height: 0.09rem;
	      }
	      .el-checkbox__label {
	        padding-left: 0.052083rem;
	        line-height: 0.098958rem;
	        font-size: 0.06rem;
	      }
	      .el-checkbox__inner:after {
	        border: 0.005208rem solid #fff;
	        border-left: 0;
	        border-top: 0;
	        height: 0.036458rem;
	        left: 0.020833rem;
	        top: 0.005208rem;
	        width: 0.015625rem;
	      }
	    }
	  }
	  .submit-btn2 {
		  
	    width: 100%;
	    // margin-top: 0.1041667rem;
	    padding: 0.0625rem 0.104167rem;
	    font-size: 0.072917rem;
		border: 1px solid rgb(65, 216, 107);
	    border-radius: 0.020833rem;
		 background-color: rgb(24,206,152);
	  }
	}
	.cardArea{
		display: flex;
		flex-wrap: wrap;
		margin-top: calc(50% - 1.2rem);
		margin-left: calc(57.8% - 2.27rem);
		width:3.24rem;
		height:1.28rem;
		border:1px solid rgb(239, 239, 239,);
		box-sizing: border-box;
		padding: .2rem;
		.cardItemActive{
			display: flex;
			align-items: center;
			//justify-content: center;
			color:#fff ;
			width: .82rem;
			height: .2rem ;
			margin: .05rem;
			border:1px solid #1FD4AD;
			background-color: #1FD4AD;
				font-size:  .08rem;
				border-radius:5px;
			img{
				height: .1rem;
				width: .1rem;
				margin-right: .05rem;
				margin-left: .1rem;
			}
		}
		.cardItem{
			display: flex;
			align-items: center;
			//justify-content: center;
			color:#1FD4AD ;
			width: .82rem;
			height: .2rem ;
			margin: .05rem;
			border:1px solid #1FD4AD;
				font-size:  .08rem;
				border-radius:5px;
			img{
				height: .1rem;
				width: .1rem;
				margin-right: .05rem;
				margin-left: .1rem;
			}
		}
	}
	.bag {
	
		height: 100vh;
		width: 42.2%;
		background: url("../../assets/img/index/bg.png") center center no-repeat;
		background-size: 100% 100%;
		
padding-left: .35rem;padding-top: .25rem;
	

		.title {
			text-align: center;
			height: 30px;
			font-size: 22px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #333333;
			line-height: 30px;
			margin-bottom: 40px;
		}

		.topLogo {
			width: 104px;
			height: 134px;

			margin-top: 57px;
		}
.inputArea{
	border-style: none;
	width: 1.75rem;
	height: .2rem;
	line-height: .2rem;
}
		.form-box-con {
			position: absolute;
			width: 2.5rem;
			height: 1rem;
			left: calc( 50% - 1.25rem);
			top: calc(50% - .5rem);
			background: #FFFFFF;
			box-shadow: 0px 1px 10px 0px rgba(49,70,94,0.2);
			border-radius: 6px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			
		}

		.form-box-con-title {
			height: 48px;
			font-size: 50px;
			font-family: AlimamaShuHeiTi;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 48px;
			margin-top: 37px;
		}
		.loginBtn{
			width: 68px;
			height: 68px;
			position: absolute;
			right: -35px;
			cursor:pointer;
		}
.form-box-con-title2{
	height: 34px;
	font-size: 36px;
	font-family: AlimamaShuHeiTi;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 34px;
	margin-top: 24px;
}
		.data-form {
			width: 407px;
			height: 100%;
			background: #fff;
			border-radius: 10px;
			padding: 40px 20px;

			.data-form-title {
				text-align: left;
				padding-bottom: 20px;
			}

			.submit-btn {
				width: 100%;
			}

			.code-time {
				width: 120px;
				text-align: center;
				color: #17b3a3;
			}
		}

		.back-login {
			font-size: 12px;
			color: #17b3a3;
			text-align: right;
		}

		.code-form-item {
			// display: flex;
			// align-items: center;
		}

		.el-form-item {
			position: relative;

			.login-icon {
				position: absolute;
				width: 20px;
				height: 20px;
				display: inline-block;
				color: #999;
				font-size: 16px;
				top: 7px;
				left: 10px;
				z-index: 10;
			}

			.el-input__inner {
				padding-left: 40px;
			}

			.code-img {
				width: 90px;
				height: 36px;
				background: #ccc;
				vertical-align: middle;
				transform: scale(1.4);
				position: absolute;
				top: 0;
				right: 20px;
			}
		}
	}

	.role-list {
		text-align: center;
		margin-bottom: 20px;

		.el-radio__label {
			color: #fff;
		}
	}

	.router-group {
		overflow: hidden;
		padding-top: 10px;

		a {
			color: #17b3a3;
		}
	}

	.footer {
		width: 100%;
		
		text-align: center;
		position: fixed;
		bottom: 94px;
		left: 0;
		font-size: 14px;
		font-family: MicrosoftYaHeiLight;
		font-weight: 400;
		color: #333333;
		line-height: 30px;
	}
</style>